import { yupResolver } from "@hookform/resolvers/yup";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useUtilities from "customHooks/useUtilities";
import chunk from "lodash.chunk";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import LiteButton from "../../components/LiteButton";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import NumericalInput from "../DonationAmount/components/NumericalInput";
import ProviderFeeStep from "../ProviderFee/ProviderFeeStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";

const TeamSupportStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("admin_support");
};

const TeamSupportStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const {
    loading,
    setTeamSupport,
    teamSupport,
    setLoading,
    checkoutOrder,
    setCheckoutOrder,
    addStepToHistory,
    teamSupportCustomAmount,
    setTeamSupportCustomAmount,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    setLoading: state.setLoading,
    checkoutOrder: state.checkoutOrder,
    setCheckoutOrder: state.setCheckoutOrder,
    setTeamSupport: state.setTeamSupport,
    teamSupport: state.teamSupport,
    teamSupportCustomAmount: state.teamSupportCustomAmount,
    setTeamSupportCustomAmount: state.setTeamSupportCustomAmount,
    loading: state.loading,
  }));
  const { data: utilities } = useUtilities();

  const { curr, currencies } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const { formatNumbers } = useFormatNumbers();
  const schema = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()

          .max(10000, t("maximum_is_x") + " 10000")
          // .positive(t('positive_only'))
          .required(t("amount_required"))
          .typeError(t("must_be_number")),
      }),
    [t]
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ amount: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: teamSupport?.toString() || "0",
    },
  });

  const handleNextSlide = async (data: { amount: string }) => {
    setLoading(true);
    try {
      if (checkoutOrder) {
        const resPromise = checkoutActions.setTeamSupport({
          amount: Number(data.amount),
          id: checkoutOrder.id,
          currency_code: currencyCode ?? curr.code,
        });
        const res = await resPromise;
        if (res.ok === true) {
          setCheckoutOrder(res.data);
          setTeamSupport(data.amount);
          if (res.data.payment_provider_fees_checkout_suggestion) {
            addStepToHistory(ProviderFeeStep.name);
          } else if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
          // navigateToSlide('teamSupport');
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (teamSupport) {
    //   setValue("amount", teamSupport);
    // }
    // if (utilities?.currencies) {
    setTeamSupportCustomAmount(true);

    setValue(
      "amount",
      // take 5 percent of total amount
      (checkoutOrder.amount * 0.05).toString()
    );
    // }
  }, [teamSupport]);

  const familierAmountsMap = chunk(
    [
      { value: "0", label: t(`none`) },
      ...(utilities?.currencies
        .find((v) => v.code === (currencyCode ?? curr.code))
        ?.familiar_amounts?.map((v) => ({
          value: v.toString(),
          label: formatNumbers(v, currencyCode ?? curr.code),
        })) || []),
      { value: "custom", label: t(`custom_amount`) },
    ],
    3
  );
  const watchAmount = watch("amount");

  return (
    <ModalBodyElementContainer className="d-flex flex-column align-items-center justify-content-center">
      <Controller
        name="amount"
        defaultValue={teamSupport?.toString()}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                marginBottom: 20,
                fontSize: 16,
              }}
            >
              {t("team_support_description")}
            </div>
            {familierAmountsMap.map((amountRow, amountIdx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 16,
                  }}
                  key={amountIdx}
                >
                  {amountRow.map((v, i) => {
                    const toRender = [];
                    if (i !== 0) {
                      toRender.push(
                        <div key={`gap-${i}`} style={{ width: 10 }} />
                      );
                    }
                    if (v.value === "custom") {
                      toRender.push(
                        <LiteButton
                          key={v.value}
                          text={v.label}
                          selected={teamSupportCustomAmount}
                          onClick={() => {
                            setTeamSupportCustomAmount(true);
                            onChange("");
                          }}
                          // style={{ width: 10,}}
                        />
                      );
                    } else {
                      toRender.push(
                        <LiteButton
                          key={v.value}
                          text={v.label}
                          selected={
                            value == v.value && !teamSupportCustomAmount
                          }
                          onClick={() => {
                            setTeamSupportCustomAmount(false);
                            onChange(v.value);
                          }}
                          // style={{ width: 10,}}
                        />
                      );
                    }
                    return toRender;
                  })}
                </div>
              );
            })}

            {teamSupportCustomAmount ? (
              <div className="mb-2 w-100">
                <NumericalInput
                  value={value}
                  onBlur={onBlur}
                  autoFocus
                  onChange={(val) => {
                    onChange(val);
                  }}
                  error={t(errors.amount?.message)}
                  currencyCode={currencyCode ?? curr.code}
                  currencySymbol={
                    currencies.find((c) => c.code == currencyCode)?.symbol ??
                    curr.symbol
                  }
                  placeholder={`${t("donation_amount_placeholder_partial")}${t(
                    `${currencyCode ?? curr.code}_currency_name`
                  )}`}
                />
              </div>
            ) : null}
            {watchAmount !== "0" && (
              <div
                className={`text-center fs-xs text-info ${
                  teamSupportCustomAmount ? "mb-2" : "mb-3"
                }`}
              >
                <i className="fe-info me-1" />
                {t("team_support_info", {
                  x: `${(
                    (Number(watchAmount) / checkoutOrder.amount) *
                    100
                  ).toFixed(0)}`,
                })}
              </div>
            )}
            <MolhamButton
              id="donate-team-support-btn"
              endIcon="fe-arrow-right"
              label={
                !watchAmount || watchAmount === "0" ? (
                  t("without_team_support")
                ) : (
                  <>
                    {t("add_team_support") + ` (`}
                    <b>
                      {formatNumbers(
                        watchAmount,
                        currencyCode ?? curr.code,
                        true
                      )}
                    </b>
                    {`)`}
                  </>
                )
              }
              className={`w-100`}
              onClick={handleSubmit(handleNextSlide)}
              disabled={loading || checkoutOrder === null}
              isLoading={loading}
            />
          </>
        )}
      />
    </ModalBodyElementContainer>
  );
};
const TeamSupportStep = {
  name: "TeamSupportStep",
  title: TeamSupportStepTitle,
  body: TeamSupportStepBody,
  // footer: TeamSupportStepFooter,
};
export default TeamSupportStep;
