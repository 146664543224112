import { usePreferenceContext } from "../context/PreferencesContext";

const useFormatNumbers = () => {
  const { curr } = usePreferenceContext();

  const formatNumbers = (
    num: number | string,
    currency?: string,
    digits?: boolean,
    noComp?: boolean
  ) => {
    const number = Number(num);

    const isCompact = curr?.code === "syp" && number >= 1000000 && !noComp;

    const processedString = number.toLocaleString("en", {
      currency: currency || curr?.code || "usd",
      currencyDisplay: "symbol",
      maximumFractionDigits: isCompact ? 2 : digits ? 2 : 0, 
      minimumFractionDigits: isCompact ? 2 : 0, 
      notation: isCompact ? "compact" : "standard",
      style: "currency",
    });

    const value = processedString.split(/(\d+)/).slice(1).join("");
    const splitCurr = processedString.split(/(\d+)/)[0];

    return `‎${splitCurr} ${value}`;
  };

  return { formatNumbers };
};

export default useFormatNumbers;
