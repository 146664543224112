export const fbPageView = () => {
  window.fbq('track', 'PageView');
};

export const fbPurchase = (value, currency = 'USD') => {
  window.fbq('track', 'Purchase', {
    value,
    currency,
  });
};

