export const sendEvent = async (
  eventName: {
    firebase?: string;
    pixel?: string;
    mixpanel?: string;
    twitter?: string;
  },
  data?: any
) => {
  return;
};
