import Link from "next/link";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { sendEvent } from "utils/sendEvent";
type Props = {
  bold?: boolean;
  fs?: string;
  id: string;
  variant?: Variant;
  onHoverVariant?: Variant;
  label?: React.ReactNode;
  startIcon?: string;
  isLoading?: boolean;
  endIcon?: string;
  disabled?: boolean;
  CustomIconButton?: React.ReactNode;
  labelClassName?: string;
  type?: "button" | "submit" | "reset";
  spinnerSize?: string;
  href?: string;
  style?: React.CSSProperties;
  hrefClassName?: string;
  hrefNoFlex?: boolean;
  hrefStyle?: React.CSSProperties;
  download?: string;
  events?: {
    name: {
      firebase: string;
      pixel: string;
      mixpanel: string;
    };
    data: any;
  };
  customHeight?: string | number;
} & React.HTMLAttributes<HTMLButtonElement>;

const MolhamButton = ({
  customHeight = "46px",
  variant = "primary",
  onHoverVariant,
  endIcon,
  label,
  labelClassName = "",
  hrefClassName = "",
  startIcon,
  type = "submit",
  isLoading,
  disabled = false,
  hrefNoFlex = false,
  CustomIconButton,
  spinnerSize = "1.2rem",
  download,
  style,
  hrefStyle,
  bold,
  events,
  fs,
  ...props
}: Props) => {
  const StartIconComp = <i className={`${startIcon}`}></i>;
  const EndIconComp = <i className={`${endIcon}`}></i>;
  const LabelComp = (
    <span
      style={bold && { fontWeight: "bold" }}
      className={
        labelClassName +
        ` ${(startIcon || CustomIconButton) && "ms-1"} ${endIcon && "me-1"} ${
          fs && `fs-` + fs
        }`
      }
    >
      {label}
    </span>
  );
  const SpinnerComp = (
    <div className="d-flex w-100 align-items-center justify-content-center">
      <Spinner
        style={{
          height: spinnerSize,
          width: spinnerSize,
        }}
        animation="border"
      />
    </div>
  );
  const [variantState, setVariant] = useState(variant);
  const onHoverVariantChange = (vari: Variant) => {
    if (onHoverVariant) setVariant(vari);
  };

  const handleOnClick = (e) => {
    if (events) {
      sendEvent(events.name, events.data);
    }
    props?.onClick(e);
  };
  const hrefFlex = hrefNoFlex
    ? ""
    : "d-flex align-items-center justify-content-center text-decoration-none ";
  const ButtonComp = (
    <Button
      onClick={props?.onClick && handleOnClick}
      type={type}
      onMouseEnter={() => onHoverVariantChange(onHoverVariant)}
      onMouseLeave={() => onHoverVariantChange(variant)}
      variant={variantState}
      style={{ height: customHeight, alignContent: "center", ...style }}
      disabled={disabled}
      {...props}
      href={null}
    >
      {isLoading ? (
        SpinnerComp
      ) : (
        <>
          {CustomIconButton}
          {startIcon && StartIconComp}
          {label && LabelComp}
          {endIcon && EndIconComp}
        </>
      )}
    </Button>
  );
  return props?.href ? (
    <Link
      className={hrefFlex + hrefClassName}
      href={props.href}
      style={hrefStyle}
      download={download}
      target={download ? "_blank" : undefined}
    >
      {ButtonComp}
    </Link>
  ) : (
    ButtonComp
  );
};

export default MolhamButton;
type Variant =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "light"
  | "dark"
  | "link"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-warning"
  | "outline-danger"
  | "outline-info"
  | "outline-light"
  | "outline-dark"
  | null;
