import { MolhamApiCurrency } from "apiTypes";
import MolhamModalOffcanvas from "components/MolhamModalOffCanvas";
import { AUTH_URL } from "config";
import useMobile from "customHooks/useMobile";
import useModal from "customHooks/useModal";
import useUtilities from "customHooks/useUtilities";
import Cookies from "js-cookie";
import getT from "next-translate/getT";

import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/postData";
import { sendEvent } from "utils/sendEvent";
import MolhamButton from "../components/ReuseableCompos/MolhamButton";
import { currencyData, langs } from "../config/preferences";
import review from "../public/lottie/cookies.json";
import { useAuthContext } from "./AuthContext";
const Lottie = dynamic(() => import("lottie-react"));

const defualtCurrencyData = {
  code: "usd",
  name: {
    ar: "دولار أمريكي",
    en: "US Dollar",
    fr: "Dollar américain",
    de: "U.S. Dollar",
    tr: "Amerikan Doları",
    es: "Dólar estadounidense",
  },
  symbol: "$",
  usd_rate: 1,
  familiar_amounts: [5, 10, 25, 50, 100],
  min_payable_amount: 1,
  accept_decimals: false,
};
type TPreferenceContext = {
  langs: {
    label: string;
    shortcut: string;
  }[];
  currencyData: {
    label: string;
    symbol: string;
    shortcut: string;
  }[];
  lang: {
    label: string;
    shortcut: string;
  };
  onLangDropdownChange: (item: { label: string; shortcut: string }) => void;
  setLang: React.Dispatch<
    React.SetStateAction<{
      label: string;
      shortcut: string;
    }>
  >;
  curr: MolhamApiCurrency;
  setCurr: React.Dispatch<React.SetStateAction<MolhamApiCurrency>>;
  currencies: MolhamApiCurrency[];
  isCurrencyLoading: boolean;
  onCurrencyDropdownChange: (
    curr: MolhamApiCurrency,
    closeModal?: () => void
  ) => void;
  handleShowLang: () => void;
  handleCloseLang: () => void;
  showLang: boolean;
  currencySelectModal: ReturnType<typeof useModal>;
  modal: ReturnType<typeof useModal>;
};
// this context is responsible for handling and passing the locale and currency
const PreferenceContext = createContext<TPreferenceContext>(null);

const PreferenceProvider = ({ children }) => {
  const router = useRouter();
  const initialRenderPreferences: {
    localeCookie: string;
    currencyCookie: string;
  } = {
    localeCookie: router.locale,
    currencyCookie: Cookies.get("currency") || "usd",
  };
  const [showLang, setShowLang] = useState(false);
  const handleShowLang = () => setShowLang(true);
  const handleCloseLang = () => setShowLang(false);
  const currencySelectModal = useModal();
  const [lang, setLang] = useState(() =>
    langs.find(
      (item) => item.shortcut === initialRenderPreferences.localeCookie
    )
  );
  const [curr, setCurr] = useState<MolhamApiCurrency>(null);

  useEffect(() => {
    const initialCurrency = Cookies.get("currencyData")
      ? JSON.parse(Cookies.get("currencyData"))
      : defualtCurrencyData;
    setCurr(initialCurrency);
  }, []);
  const { data: utilities } = useUtilities();

  useEffect(() => {
    //  prferences.currencyCookie i got it from passing the cookie string from getInitialProps in  the _app to the context
    if (utilities?.currencies) {
      const selectedCurrency = utilities?.currencies?.find(
        (item) => item.code === initialRenderPreferences.currencyCookie
      );
      if (selectedCurrency) {
        setCurr(selectedCurrency);
        Cookies.set("currencyData", JSON.stringify(selectedCurrency));
      }
    }
  }, [utilities?.currencies]);

  const { donor, setDonor } = useAuthContext();
  const donorAvailable = donor && donor?.id;
  const { t } = useTranslation("common");

  useEffect(() => {
    Cookies.remove("NEXT_LOCALE");
    const red = utilities?.home_page_force_redirection_path;
    const visited = sessionStorage.getItem("visited");
    const visitedRed = sessionStorage.getItem("visitedRed");
    if (donorAvailable) {
      const { locale } = donor;
      if (router.pathname === "/" && locale && !visited) {
        if (locale !== router.locale) {
          if (red && !visitedRed) {
            sessionStorage.setItem("visitedRed", "true");
            router.push(red, red, { locale });
          } else {
            router.push(router.asPath, router.asPath, { locale });
            setLang(() => langs.find((item) => item.shortcut === locale));
          }
        }
      } else if (!locale) {
        if (red && !visitedRed && router.pathname === "/") {
          sessionStorage.setItem("visitedRed", "true");
          router.push(red);
        }
        const def = langs.find((item) => item.shortcut === router.locale);
        onLangDropdownChange(def);
        Cookies.set("locale", def.shortcut);

        setLang(() => {
          return langs.find((item) => item.shortcut === router.locale);
        });
        Cookies.set("locale", locale);
      } else {
        if (red && !visitedRed && router.pathname === "/") {
          sessionStorage.setItem("visitedRed", "true");
          router.push(red);
        }
      }
      sessionStorage.setItem("visited", "true");
    } else {
      if (red && !visitedRed && router.pathname === "/") {
        sessionStorage.setItem("visitedRed", "true");
        router.push(red);
      }
      setLang(() => {
        return langs.find((item) => item.shortcut === router.locale);
      });
      Cookies.set("locale", router.locale);
    }
  }, [donor, utilities?.home_page_force_redirection_path]);

  // useEffect for handling currencies
  useEffect(() => {
    if (donorAvailable && utilities?.currencies) {
      const { currency } = donor;

      if (currency) {
        const newCurrency = utilities.currencies.find(
          (item) => item.code.toLowerCase() === currency.code.toLowerCase()
        );

        setCurr(newCurrency);

        if (isObj(newCurrency)) {
          Cookies.set("currencyData", JSON.stringify(newCurrency));
        }

        Cookies.set(
          "currency",
          isObj(currency) ? currency?.code.toLowerCase() : currency
        );
      }
    }
  }, [donor, utilities?.currencies]);

  const onCurrencyDropdownChange = async (
    _curr: MolhamApiCurrency,
    closeModal: () => void
  ) => {
    const currency = utilities?.currencies.find(
      (item) => item.code.toLowerCase() === _curr.code.toLowerCase()
    );
    if (!currency) return;
    if (donorAvailable) {
      try {
        await toast.promise<unknown, { message: string }>(
          postData<{ message: string }>(AUTH_URL, {
            currency_code: currency.code,
          }),
          {
            pending: t("loading"),
            success: `${t(`change_currency_toast`)} ${
              currency.name[router.locale]
            }`,
            error: {
              render({ data }) {
                return t("cant_change_currency_alert");
              },
            },
          },
          {
            delay: 0,
            autoClose: 10000,
          }
        );
        Cookies.set("currency", currency.code);
        Cookies.set("currencyData", JSON.stringify(currency));
        setCurr(currency);
      } catch (error) {
        sendEvent(
          {
            mixpanel: "change_currency_error",
          },
          error
        );
      }
    } else {
      setCurr(currency);
      Cookies.set("currency", currency.code);
      Cookies.set("currencyData", JSON.stringify(currency));
    }
    closeModal();
  };
  const onLangDropdownChange = async (item: {
    label: string;
    shortcut: string;
  }) => {
    Cookies.set("locale", item.shortcut);
    setLang(() => langs.find((lang) => lang.shortcut === item.shortcut));
    // handleCloseModalOffCanvas();

    if (donorAvailable) {
      try {
        await postData(AUTH_URL, {
          locale: item.shortcut,
        });
        await setDonor((prev) => ({ ...prev, locale: item.shortcut }));
      } catch (error) {}
    }
    const trans = await getT(item.shortcut, "common");
    toast.success(`${trans(`change_language_toast`)} ${item.label}`);
    router.push(router.asPath, router.asPath, { locale: item.shortcut });
  };

  const modal = useModal();

  useEffect(() => {
    const consentCookie = Cookies.get("consentCookie");
    if (!consentCookie || consentCookie === "false") {
      Cookies.remove("cookiesConsent");
      modal.handleShowModal();
    }
  }, []);
  const onCookieAccept = () => {
    Cookies.set("consentCookie", "true", {
      expires: new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000),
      //in 1 minute
      // expires: new Date(Date.now() + 30 * 1000),
    });
    modal.handleCloseModal();
  };
  const PrivLink = (
    <Link
      onClick={onCookieAccept}
      key={"priv"}
      href="/help/privacy"
      className="text-decoration-none"
    >
      <a />
    </Link>
  );
  const value = useMemo(() => {
    return {
      langs,
      showLang,
      handleShowLang,
      handleCloseLang,
      currencyData,
      lang,
      setLang,
      curr,
      setCurr,
      currencies: utilities?.currencies,
      isCurrencyLoading: !utilities?.currencies,
      onLangDropdownChange,
      onCurrencyDropdownChange,
      currencySelectModal,
      modal,
    };
  }, [
    showLang,
    lang,
    curr,
    utilities?.currencies,
    donorAvailable,
    currencySelectModal.showModal,
  ]);
  const isMobile = useMobile("md");
  return (
    <>
      <MolhamModalOffcanvas
        show={modal.showModal}
        onHide={null}
        size="md"
        swipeToClose={false}
        body={{ style: { overflow: "visible" }, className: "p-0" }}
        modalStyle={{ overflow: "visible" }}
        modalClassName="overflow-visible"
      >
        <div className="position-relative " style={{ overflow: "visible" }}>
          <Lottie
            className="position-absolute m-auto"
            style={{
              width: 80,
              top: "-50px",
              right: 0,
              left: 0,
              overflow: "visible",
            }}
            animationData={review}
            loop={true}
          />
        </div>
        <div className="d-flex justify-content-center flex-column w-100 p-3 user-select-none">
          <div className={`${isMobile ? "fs-16" : "fs-18"} my-3 text-center`}>
            {t("cookies_consent_desc")}
          </div>

          <div className="d-flex justify-content-between">
            <MolhamButton
              id="cookie-privacy-btn"
              label={
                t("privacy_policy").length > 23
                  ? t("privacy_policy").slice(0, 23) + "..."
                  : t("privacy_policy")
              }
              variant={null}
              href="/help/privacy"
              onClick={onCookieAccept}
              hrefClassName={"cookie-privacy-btn"}
              hrefStyle={{
                width: "48%",
              }}
            />
            <MolhamButton
              id="cookie-accept-btn"
              label={t("i_consent")}
              onClick={onCookieAccept}
              variant="primary"
              style={{
                width: "48%",
              }}
            />
          </div>
        </div>
      </MolhamModalOffcanvas>
      <PreferenceContext.Provider value={value}>
        {children}
      </PreferenceContext.Provider>
    </>
  );
};
export function usePreferenceContext(): TPreferenceContext {
  const prefData = useContext(PreferenceContext);
  if (prefData == null) {
    throw new Error(
      "PreferenceContext is not available. Make sure you are rendering the component within the PreferenceProvider."
    );
  }
  return prefData;
}

export { PreferenceContext, PreferenceProvider };
const isObj = (obj): obj is object => typeof obj === "object" && obj !== null;
