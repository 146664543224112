import useTranslation from "next-translate/useTranslation";
import { useMemo, useState } from "react";
import { useCheckoutStore } from "../../../CheckoutState";
import swishActions from "../../../actions/swish";

import { useAuthContext } from "context/AuthContext";
import { useEffect } from "react";

import dynamic from "next/dynamic";
import useSWR from "swr";
import checkoutHandlerActions from "../../../actions/checkout-handler";
import ModalBodyElementContainer from "../../../components/ModalBodyElementContainer";
import CheckoutEndStep from "../../CheckoutEnd/CheckoutEndStep";

const QRCode = dynamic(() =>
  import("react-qrcode-logo").then((mod) => mod.QRCode)
);
type Props = {};

const SwishDesktopForm: React.FC<Props> = () => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [checkoutOrder] = useCheckoutStore((state) => [state.checkoutOrder]);
  const [paymentRequest, setPaymentRequest] = useState<{
    id: string;
    token: string;
  } | null>(null);
  const [callbackUrl, setCallbackUrl] = useState<string | null>(null);
  const { donor } = useAuthContext();
  const onSubmit = async () => {
    const paymentProvider =
      donor!.available_payment_methods.swish.payment_provider;
    if (paymentRequest) {
      const checkoutEndRes = await checkoutHandlerActions.handleCheckoutSource({
        payment_provider_id: paymentProvider.id,
        type: checkoutHandlerActions.CheckoutSourceTypes.swish.payment_request,
        reference: paymentRequest.id,
      });
      if (checkoutEndRes.ok === true) {
        if (
          checkoutEndRes.data?.checkout_order?.status === "paid" ||
          checkoutEndRes.data?.checkout_order?.status === "processing"
        ) {
          const checkoutModalState = useCheckoutStore.getState();
          checkoutModalState.setCheckoutEnd(checkoutEndRes.data);
          checkoutModalState.addStepToHistory(CheckoutEndStep.name);
        }
      }
    }
  };
  const {} = useSWR("/submit/checkout", {
    refreshInterval: 5,
    fetcher: onSubmit,
  });

  const createPaymentRequest = async (payerAlias?: string) => {
    if (!loading) {
      setLoading(true);
      try {
        const swishPaymentRequestResult = await swishActions.paymentRequest(
          checkoutOrder!.reference
        );
        if (
          swishPaymentRequestResult.ok &&
          swishPaymentRequestResult.data &&
          swishPaymentRequestResult.data.token &&
          swishPaymentRequestResult.data.id
        ) {
          setPaymentRequest(swishPaymentRequestResult.data);
        } else if (!swishPaymentRequestResult.ok) {
          setLoading(false);

          return null;
        }
      } catch (error) {
        setLoading(false);

        return null;
      }
      setLoading(false);
    }
  };
  const paymentRequestUrl = useMemo(() => {
    if (!checkoutOrder.id || !paymentRequest?.token) {
      return null;
    }
    return "D" + paymentRequest?.token;

    // const calbackUrlSearchParams = new URLSearchParams({
    //   swish_payment_request_id: paymentRequest.id,
    //   checkout_order_id: checkoutOrder!.id.toString(),
    // });
    // const callbackurl =
    //   location.protocol +
    //   "//" +
    //   location.host +
    //   location.pathname +
    //   "?" +
    //   calbackUrlSearchParams.toString();
    // const swishSearchParams = new URLSearchParams({
    //   token: paymentRequest.token,
    //   callbackurl,
    // });
    // donor?.tester && setCallbackUrl(callbackurl);
    // const url = "swish://paymentrequest?" + swishSearchParams.toString();
    // return url;
  }, [checkoutOrder.id, paymentRequest?.token]);
  useEffect(() => {
    if (!paymentRequest) {
      createPaymentRequest();
    }
  }, []);
  return (
    <>
      {paymentRequestUrl ? (
        <>
          <ModalBodyElementContainer className="pb-2">
            {donor?.tester && callbackUrl ? (
              <a href={callbackUrl}>callbackUrl</a>
            ) : null}
            <p>{t("scan_swish_qr")}</p>
            <div className="px-4 d-flex justify-content-center align-items-center mb-4 ">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                value={paymentRequestUrl}
                logoWidth={30}
                logoHeight={30}
                logoPadding={2}
                logoPaddingStyle="circle"
                removeQrCodeBehindLogo
                logoImage={"https://cdn.molhamteam.com/assets/img/swish/swish.png"}
              />
            </div>
          </ModalBodyElementContainer>
        </>
      ) : null}
      <ModalBodyElementContainer></ModalBodyElementContainer>
    </>
  );
};

export default SwishDesktopForm;
