import CustomContentLoader from "components/ReuseableCompos/CustomContentLoader";
import SvgCreator from "components/ReuseableCompos/SvgCreator";
import { useModeContext } from "context/ModeContext";
import useUtilities from "customHooks/useUtilities";
import { memo } from "react";
import { ReactSVG } from "react-svg";

type Props = {
  hwm?: string;
  hwd?: string;
};

const LogoCreator = ({ hwm, hwd }: Props) => {
  const { theme } = useModeContext();
  const { data: utilities } = useUtilities();

  const uri =
    utilities?.assets?.home_page_logo_url ||
    "https://cdn.molhamteam.com/assets/logo/logo-main.svg";
  if (!utilities) return null;
  if (uri.includes("ramadan")) {
    return (
      <SvgComponent
        uri={theme === "dark" ? "/assets/rmdiconwh.svg" : "/assets/rmdicon.svg"}
        hw={hwd || "40px"}
      />
    );
  }
  return (
    <SvgCreator
      color={theme === "dark" ? "#fff" : "var(--primary2)"}
      uri={uri}
      hwm={hwm || "40px"}
      hwd={hwd || "40px"}
      p={"p-0"}
      noStroke={uri.includes("logo-main")}
    />
  );
};

export default memo(LogoCreator);
const SvgComponent = ({ hw, uri }: { hw: string; uri: string }) => (
  <ReactSVG
    loading={() => (
      <CustomContentLoader viewBox={`0 0 ${hw} ${hw}`} width={hw} height={hw}>
        <rect x="0" y="0" rx="8" ry="8" width={hw} height={hw}></rect>
      </CustomContentLoader>
    )}
    src={uri}
    beforeInjection={(svg) => {
      svg.setAttribute("width", hw);
      svg.setAttribute("height", hw);
    }}
  />
);
