import LowerNavbar from "components/NavBar/LowerNavbar";
import CurrencySelectModal from "components/Profile/ReusableComponents/CurrencySelectModal";
import LanguageSelectModal from "components/Profile/ReusableComponents/LanguageSelectModal";
import GetAppModal from "components/ReuseableCompos/GetAppModal";
import InAppMsgModal from "components/ReuseableCompos/InAppMsgModal";
import MotivationModal from "components/ReuseableCompos/MotivationModal";
import ReviewModal from "components/ReuseableCompos/ReviewModal";
import {
  resetCheckoutStore,
  useCheckoutStore,
} from "components/ReuseableCompos/SharedDonatePages/DonateModal/CheckoutState";
import GeneralDonationStep from "components/ReuseableCompos/SharedDonatePages/DonateModal/Steps/GeneralDonation/GeneralDonationStep";
import axiosInstance from "components/ReuseableCompos/SharedDonatePages/DonateModal/axiosInstance";
import { useAuthContext } from "context/AuthContext";
import { AuthModalContext } from "context/AuthModalContext";
import { FooterProvider } from "context/FooterContext";
import useMobile from "customHooks/useMobile";
import Cookies from "js-cookie";
import { NextSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { ToastContainer, Theme as ToastTheme, Zoom } from "react-toastify";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { manageSessionReference } from "utils/manageSessionReference";
import MobileFooterPush from "../components/Footer/MobileFooterPush";
import FooterNav from "../components/FooterNav";
import InitalLoader from "../components/InitalLoader";
import Navbar from "../components/NavBar/Navbar";
import AuthModal from "../components/ReuseableCompos/AuthModal";
import { CartProvider } from "../context/CartContext";
import { FilterOffcanvasProvider } from "../context/FilterOffcanvasContext";
import { useModeContext } from "../context/ModeContext";
import { NavHeaderProvider } from "../context/NavHeaderContext";
import { NotificationProvider } from "../context/NotificationContext";
import useLastPath from "../customHooks/useLastPath";
import useRTL from "../customHooks/useRTL";

const Layout = ({ children, mainClassname = "" }) => {
  const { theme, color } = useModeContext();
  const { asPath, ...router } = useRouter();

  const dynamicPageTitle = asPath?.split("/").filter((item) => item !== "");

  const { t, lang } = useTranslation("common");
  const isRTL = useRTL();

  const _pathSliceLength = Math.min.apply(Math, [
    asPath.indexOf("?") > 0 ? asPath.indexOf("?") : asPath.length,
    asPath.indexOf("#") > 0 ? asPath.indexOf("#") : asPath.length,
  ]);
  const lastPath = useLastPath();
  const matched = useMobile("lg");
  //get scrollbar width

  const { donor } = useAuthContext();
  const { handleShowAuthModal, setStep } = useContext(AuthModalContext);
  const showAuthModalAction = (step: number) => {
    setStep(step);
    handleShowAuthModal();
    const newURL = new URL(window.location.href);
    router.replace(router.pathname + newURL.search, undefined, {
      shallow: true,
    });
  };
  useEffect(() => {
    const handler = () => {
      const url = window.location.href;
      const moclid = new URL(url).searchParams.get("moclid");
      if (moclid) {
        Cookies.set("moclid", moclid, {
          expires: new Date(Date.now() + 3600000),
        });
      }
      if (donor && !manageSessionReference().getSessionReference()) {
        axiosInstance
          .post<{
            reference: string;
          }>(`/auth/sessions`)
          .then((res) => {
            manageSessionReference().setSessionReference(res.data.reference);
          })
          .catch((err) => {});
      }
      if (!url.includes("#")) return;
      const hash = url.split("#")[1];
      if (hash === "quick_donate") {
        const { addStepToHistory, setModalVisible, modalVisible } =
          useCheckoutStore.getState();
        if (!modalVisible) {
          resetCheckoutStore();
          addStepToHistory(GeneralDonationStep.name);
          setModalVisible(true);
          // router.push(router.pathname, undefined, { shallow: false });
          const newURL = new URL(window.location.href);
          newURL.hash = "";
          router.replace(newURL.pathname + newURL.search, undefined, {
            shallow: true,
          });
        }
      }
    };
    handler();
  }, [donor, router]);
  const isMobile = useMobile("lg");
  // const { pathname } = useRouter();
  // const scrollCondition = pathname !== "/";
  const scrollCondition = true;
  // const titleTranslations = {
  //   donations: "donations_record",
  //   molham: "about_molham",
  //   programs: "ourdepsandprojects",
  //   privacy: "privacy_policy",
  //   "contact-us": "contact_us",
  //   about: "abouttheteam",
  //   terms: "payment_process_terms_of_use",
  //   transparency: "transparency_file",
  //   outstanding: "profile-payments-outstanding",
  // };
  return (
    // the main structure of the entire website
    <>
      <NextSeo
        canonical={
          "https://molhamteam.com" +
          (router.locale === "ar" ? "" : "/" + router.locale) +
          asPath
        }
        title={t(`molham_volunteering_team`)}
        defaultTitle={t(`molham_volunteering_team`)}
        description={t(`molham_volunteering_team_website`)}
        openGraph={{
          url:
            "https://molhamteam.com" +
            (router.locale === "ar" ? "" : "/" + router.locale) +
            asPath,
          // title: t(`molham_volunteering_team`),
          // description: t(`molham_volunteering_team_website`),
          // images: [
          //   {
          //     url: "https://molhamteam.com/" + molhamLogo256,
          //     width: 256,
          //     height: 256,
          //     alt: t(`molham_volunteering_team`),
          //   },
          // ],
          site_name: t(`molham_volunteering_team`),
          type: "website",
          locale: lang,
        }}
        twitter={{
          handle: "@molhamteam",
          site: "@molhamteam",
          cardType: "summary_large_image",
        }}
        additionalMetaTags={[
          {
            name: "author",
            content: t(`molham_volunteering_team`),
          },
          {
            name: "viewport",
            content:
              "minimum-scale=1, initial-scale=1, width=device-width,height=device-height, shrink-to-fit=no, user-scalable=yes, viewport-fit=cover",
          },
          {
            name: "viewport",
            content:
              "viewport-fit=cover,height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
          },
          {
            name: "theme-color",
            content: `${theme === "dark" ? "#15202b" : "#ffffff"}`,
          },
        ]}
      />

      <ThemeProvider theme={{ theme, color, rtl: isRTL }}>
        <GlobalStyles isRTL={isRTL} />

        <InitalLoader />
        <main
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // transition={{ delay: 1.2 }}
        >
          {/* Toast Container */}

          <ToastContainer
            className="toastify-container"
            transition={Zoom}
            rtl={isRTL}
            position={isRTL ? "bottom-right" : "bottom-left"}
            hideProgressBar={true}
            autoClose={3500}
            theme={theme as ToastTheme}
            bodyStyle={{
              fontFamily: "var(--font-bahij)",
            }}
          />

          {/* main Context */}

          <CartProvider>
            <FooterProvider>
              <NotificationProvider>
                <FilterOffcanvasProvider>
                  <NavHeaderProvider>
                    <Navbar />
                    <LowerNavbar />
                    <div
                      className={
                        // isMobile && scrollCondition
                        // ? "content-wrap-mobile" :
                        "content-wrap"
                      }
                    >
                      {children}
                    </div>
                    <ReviewModal />
                    <MotivationModal />
                    <GetAppModal />
                    <InAppMsgModal />
                    <AuthModal />
                    <LanguageSelectModal />
                    <CurrencySelectModal />
                    <FooterNav />
                  </NavHeaderProvider>
                </FilterOffcanvasProvider>
              </NotificationProvider>
            </FooterProvider>
          </CartProvider>

          {/* footer */}
          {matched && <MobileFooterPush />}
          {/* <Footer /> */}
        </main>
      </ThemeProvider>
    </>
  );
};

export default Layout;

// determine what color has been selected
// and injecting it inside the root of the main css file to change it
// global stylees is global styled components function that control ur css files
const GlobalStyles = createGlobalStyle<{ isRTL: boolean; theme: any }>`
:root{                       //theme ==='THEME' if ? 'return me a color' else :  "return me another color"
  --white : ${({ theme }) => (theme.theme === "light" ? "#ffffff" : "#15202b")};
  --primary : ${({ theme }) => colorDetector(theme).primary};
  --light-primary: ${({ theme }) => colorDetector(theme).lightPrimary} ;
  --light-trans-primary: ${({ theme }) =>
    colorDetector(theme).lightTransPrimary} ;
  --trans-primary : ${({ theme }) => colorDetector(theme).transPrimary};
  --active-primary : ${({ theme }) => colorDetector(theme).activePrimary} ;
  --primary2 : ${({ theme }) => colorDetector(theme).primary2};
  --gray : ${({ theme }) => (theme.theme === "light" ? "#5a5b75" : "#fff")};
  --nav-shadow:  ${({ theme }) =>
    theme.theme === "light"
      ? " 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1)"
      : "0 0 0.025rem 1px rgba(255 , 255, 255 , 0.1)"};
  --main-shadow:  ${({ theme }) =>
    theme.theme === "light"
      ? "-0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04)"
      : "0 0 0.025rem 1px rgba(255 , 255, 255 , 0.07)"};
  --light-gray :  ${({ theme }) =>
    theme.theme === "light" ? "#F2F2F2" : "#22303C"};
  --text-white : ${({ theme }) =>
    theme.theme === "light" ? "#4a4b65" : " #fff"};
  --text-muted : ${({ theme }) =>
    theme.theme === "light" ? "#6c757d" : " #b0b3b8"};
  --separator-mobile: ${({ theme }) =>
    theme.theme === "light" ? "#E9E9F2" : "#22303C"};
  --input-gray :  ${({ theme }) =>
    theme.theme === "light" ? "  #fff" : "#22303c"};  
  --dark-gray :  ${({ theme }) =>
    theme.theme === "light" ? "#4a4b65" : "#e4ecf0"};
  --light-white : ${({ theme }) =>
    theme.theme === "light" ? "#f7f7fc" : "#15202b"};
  --sidebar-bg : ${({ theme }) =>
    theme.theme === "light" ? "#f7f7fc" : "#1b2733"};
  --svg-logo-loader : ${({ theme }) =>
    theme.theme === "light" ? " #1768b3" : "#fff"};
  --tab-label-color :  ${({ theme }) =>
    theme.theme === "light" ? "rgba(0, 0, 0, 0.54)" : "#fff"};
  --body-gray : ${({ theme }) =>
    theme.theme === "light" ? "#737491" : "#E4E6EB"}; 
  --feed-bg : ${({ theme }) =>
    theme.theme === "light" ? " #f7f7f8 " : "var(--black)"};
  --form-control-color : ${({ theme }) =>
    theme.theme === "light" ? " #737491" : "rgba(255,255,255,0.87)"};
    --primary-transparent : ${({ theme }) =>
      theme.theme === "light"
        ? " rgba(255, 255, 255, 0.8)"
        : "rgba(21, 32, 43 , 0.7)"};

  --lastupdate-border : ${({ theme }) =>
    theme.theme === "light" ? " #e9e9f0" : "#22303c"};
--cookie-btn-shadow : ${({ theme }) =>
  theme.theme === "light" ? "rgba(171,171,171,0.55)" : "rgba(0,0,0,0.55)"};
--cookie-btn-shadow-hover : ${({ theme }) =>
  theme.theme === "light" ? "rgba(171,171,171,0.75)" : "rgba(0,0,0,0.75)"};
}
body{
     /* here we are passing the theme prop from ThemeProvider and change the color to the value that we got from our context */
  /* background: ${({ theme }) =>
    theme.theme === "light" ? "var(--white)" : "var(--black)"} !important; */
    color: ${({ theme }) => (theme.theme === "light" ? "#737491" : "#E4E6EB")} ;
}
:root {
  color-scheme: ${({ theme }) => (theme.theme === "light" ? "light" : "dark")} ;
}
.bg-light {
    background-color: ${({ theme }) =>
      theme.theme === "light" ? "var(--white)" : "var(--black)"} !important;
} 
.bg-dark {
  background-color: ${({ theme }) =>
    theme.theme === "light" ? "#37384e" : "#15202b"} !important;
} 
.bg-squircle{
  background-color: ${({ theme }) =>
    theme.theme === "light" ? "#f2f2f2" : " #22303C"} !important;
}
.bg-secondary , .btn-secondary{
  background-color: ${({ theme }) =>
    theme.theme === "light" ? "#f7f7fc" : " #192734"} !important;
}
.bg-skeleton {
  background-color: ${({ theme }) =>
    theme.theme === "light" ? "#E4E6EB" : " rgb(228 230 235 / 40%)"} !important;
}

.bg-swipedown-pill {
  background-color:   ${({ theme }) =>
    theme.theme === "light" ? "#e9e9f0" : "#222f38"} !important;
}
.text-body {
  color:   ${({ theme }) =>
    theme.theme === "light" ? "#737491" : " var(--main-gray)"} !important;
}

// theme.theme === "light" ? "#737491" : " #fff"} !important;
.text-white {
  color:   ${({ theme }) =>
    theme.theme === "light" ? "#4a4b65" : " #fff"} !important;
}
.text-muted {
  color:   ${({ theme }) =>
    theme.theme === "light" ? "#6c757d" : " #b0b3b8"} !important;
  }
  // muted could be #c0c0c0 or #bebebe on dark mode
.text-contrast {
  color:   ${({ theme }) =>
    theme.theme === "light" ? "#fff" : " #4a4b65"} !important;
}
.meta-link{
  color:   ${({ theme }) =>
    theme.theme === "light" ? "#797a95" : " #B0B3B8"} ;
}
.meta-link:hover {
  color:   ${({ theme }) => (theme.theme === "light" ? "#4d4e64" : "#E4E6EB")} ;
}
.card , .border-gray {
  border: ${({ theme }) =>
    theme.theme === "light"
      ? "1px solid rgba(0, 0, 0, 0.085)"
      : "1px solid var(--light-gray)"} ;
}
.btn , .btn-text-color{
  color:  ${({ theme }) => (theme.theme === "light" ? "#737491" : "#B0B3B8")} ;
}
.btn:hover {
  color:  ${({ theme }) => (theme.theme === "light" ? "#737491" : "#B0B3B8")} ;

}
.btn-text-color {
  color:  ${({ theme }) =>
    theme.theme === "light" ? "#737491" : "#B0B3B8"} !important;
}
.react-datepicker{
  background-color:  ${({ theme }) =>
    theme.theme === "light" ? "#fff" : "#15202B"} !important;
}
.react-datepicker__day{
  color :  ${({ theme }) => (theme.theme === "light" ? "#000" : "#fff")} ;
}

.form-select  {
  background-image:  ${({ theme }) =>
    theme.theme === "light"
      ? `url(
          "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%234a4b65' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"
        )`
      : `url(
          "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"
        )`};
}   
.form-control , .PhoneInputInput ,.css-yk16xz-control {
    color:  var(--form-control-color);
    border-color: ${({ theme }) =>
      theme.theme === "light" ? " #dfdfeb" : "#2b3944"} !important;
    background-color:  ${({ theme }) =>
      theme.theme === "light" ? "  #fff" : "#22303c"} !important;  
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5625rem 1.125rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #b0b3b8;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #dfdfeb;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  border-color: ${({ theme }) =>
    theme.theme === "light" ? " #dfdfeb" : "#2b3944"} !important;
    background-color:  ${({ theme }) =>
      theme.theme === "light" ? "  #fff" : "#22303c"} !important;  
  border-radius: 0.75rem;
}
.form-control:focus + .input-group-text {

  border-color: var(--primary2) !important;

  background-color: ${({ theme }) =>
    theme.theme === "light" ? "var(--white)" : "var(--white)"} !important;  
}
.form-control-plaintext{
  color:  var(--form-control-color);
}

.css-1uccc91-singleValue{
  color:  ${({ theme }) =>
    theme.theme === "light" ? "#737491 " : "rgba(255,255,255,0.87)"} !important;
}
.form-control:focus , .PhoneInputInput:focus ,.css-yk16xz-control:focus , .css-1pahdxg-control{
  /* dark */
  color:  ${({ theme }) =>
    theme.theme === "light" ? "#737491 " : "rgba(255,255,255,0.87)"} !important;
  /* color: #737491; */
  background-color: ${({ theme }) =>
    theme.theme === "light" ? "var(--white)" : "var(--white)"} !important;  
   border-top-color: var(--primary2) !important;
  border-bottom-color: var(--primary2) !important;
  border-left-color: var(--primary2) !important;
 
  border-right-color: var(--primary2) !important;
   /* ${({ theme, isRTL }) =>
     !isRTL
       ? theme.theme === "light"
         ? " #dfdfeb"
         : "#2b3944"
       : "var(--primary2)"} !important; 
      /* ${({ theme, isRTL }) =>
        isRTL
          ? theme.theme === "light"
            ? " #dfdfeb"
            : "#2b3944"
          : "var(--primary2)"} !important;*/
 

}
.form-switch .form-check-input {
    height: 1.25em;
    border: 0;
    background-color:${({ theme }) =>
      theme.theme === "light" ? "#e3e3ee" : "#B0B3B8"}; ;
}
.dark-border-bottom{
  border-bottom: ${({ theme }) =>
    theme.theme === "light" ? "none" : "1px solid #333a40"} ;
}
.dark-border-start{
  border-left: ${({ theme }) =>
    theme.theme === "light"
      ? "1px solid var(--light-gray)"
      : "1px solid #333a40"} !important;
}
.dark-border-end{
  border-right: ${({ theme }) =>
    theme.theme === "light"
      ? "1px solid var(--light-gray)"
      : "1px solid #333a40"} !important;
}
/* .dark-border-color{
  border-color : ${({ theme }) =>
    theme.theme === "light" ? "none" : "#333a40"} !important;
} */
.dark-border-top{
  border-top : ${({ theme }) =>
    theme.theme === "light" ? "none" : "1px solid #333a40"} ;
}
.dark-border{
  border: ${({ theme }) =>
    theme.theme === "light" ? "none" : "1px solid #333a40"}!important ;
}
.navbar-sticky.navbar-stuck {
  box-shadow:  ${({ theme }) =>
    theme.theme === "light" ? "var(--nav-shadow)" : "none"} ;
}
.navbar-box-shadow{
  box-shadow:  ${({ theme }) =>
    theme.theme === "light" ? "var(--nav-shadow)" : "none"} ;
}
button.close ,button.back, .modal-header .back {
    background: ${({ theme }) =>
      theme.theme === "light"
        ? "rgb(133 123 123 / 25%) "
        : "var(--white-trans)"} ; 
  }
.modal-header .close {
  background: ${({ theme }) =>
    theme.theme === "light"
      ? "rgb(133 123 123 / 25%) "
      : "var(--white-trans)"} ; 
  }
 
.logost0{
  fill: ${({ theme }) =>
    theme.theme === "light" ? "var(--primary2)" : "#fff"} ; 
}

.stop-color1{
  stop-color: ${({ theme }) =>
    theme.theme === "light" ? "var(--primary2)" : "#fff"} ; 
  
}
.stop-color2{
  stop-color :  ${({ theme }) =>
    theme.theme === "light" ? "var(--primary)" : "#fff"} ; 
}

`;
// returning color upon the selected color
export const colorDetector = ({ color, theme }) => {
  if (color === "blue" && theme === "light") {
    return {
      primary: "#1768b3",
      primary2: "#1768b3",
      lightPrimary: "#649ACC",
      transPrimary: "rgba(23, 104, 179, 0.8)",
      lightTransPrimary: "rgba(23, 104, 179, 0.22)",
      activePrimary: "#12538f",
    };
  }
  if (color === "blue" && theme === "dark") {
    return {
      primary: "#124D84",
      primary2: "#006fd5",
      lightPrimary: "#559FE3",
      transPrimary: "rgba(0, 111, 213, 0.8)",
      lightTransPrimary: "rgba(0, 111, 213, 0.22)",
      activePrimary: "#12538f",
    };
  }

  if (color === "teal" && theme === "light") {
    return {
      primary: "#008796",
      primary2: "#008796",
      lightPrimary: "#55AFB9",
      transPrimary: "rgba(0, 135, 150, 0.8)",
      lightTransPrimary: "rgba(0, 135, 150, 0.22)",
      activePrimary: "#006c78",
    };
  }
  if (color === "teal" && theme === "dark") {
    return {
      primary: "#085d67",
      primary2: "#008796",
      lightPrimary: "#55AFB9",
      transPrimary: "rgba(0, 135, 150, 0.8)",
      lightTransPrimary: "rgba(0, 135, 150, 0.22)",
      activePrimary: "#006c78",
    };
  }
  if (color === "purple" && theme === "light") {
    return {
      primary: "#6c207b",
      primary2: "#6c207b",
      lightPrimary: "#9D6AA7",
      transPrimary: "rgba(137, 77, 149, 0.8)",
      lightTransPrimary: "rgba(137, 77, 149, 0.22)",
      activePrimary: "#561a62",
    };
  }
  if (color === "purple" && theme === "dark") {
    return {
      primary: "#682475",
      primary2: "#b408d6",
      lightPrimary: "#b408d6",
      transPrimary: "rgba(180, 8, 214, 0.8)",
      lightTransPrimary: "rgba(180, 8, 214, 0.22)",
      activePrimary: "#9006ab",
    };
  }
  if (color === "maroon" && theme === "light") {
    return {
      primary: "#AC1C37",
      primary2: "#AC1C37",
      lightPrimary: "#9e3f50",
      transPrimary: "rgba(172, 28, 55 , 0.8)",
      lightTransPrimary: "rgba(172, 28, 55,0.22)",
      activePrimary: "#aa001f",
    };
  }
  if (color === "maroon" && theme === "dark") {
    return {
      primary: "#AC1C37",
      primary2: "#AC1C37",
      lightPrimary: "#9e3f50",
      transPrimary: "rgba(172, 28, 55 , 0.8)",
      lightTransPrimary: "rgba(172, 28, 55 , 0.22)",
      activePrimary: "#aa001f",
    };
  }
  if (color === "phosphoric" && theme === "light") {
    return {
      primary: "#becf44",
      primary2: "#becf44",
      lightPrimary: "#e0ef6f",
      transPrimary: "rgba(182, 196, 73 , 0.8)",
      lightTransPrimary: "rgba(182, 196, 73 , 0.22)",
      activePrimary: "#b0c700",
    };
  }
  if (color === "phosphoric" && theme === "dark") {
    return {
      primary: "#97a626",
      primary2: "#97a626",
      lightPrimary: "#e0ef6f",
      transPrimary: "rgba(182, 196, 73 , 0.8)",
      lightTransPrimary: "rgba(182, 196, 73 , 0.22)",
      activePrimary: "#b0c700",
    };
  }
  if (color === "orange" && theme === "light") {
    return {
      primary: "#ED7C02",
      primary2: "#ED7C02",
      lightPrimary: "#ebab67",
      transPrimary: "rgba(237, 124, 2 , 0.8)",
      lightTransPrimary: "rgba(237, 124, 2 , 0.22)",
      activePrimary: "#dc7100",
    };
  }
  if (color === "orange" && theme === "dark") {
    return {
      primary: "#ba6305",
      primary2: "#ba6305",
      lightPrimary: "#ebab67",
      transPrimary: "rgba(237, 124, 2 , 0.8)",
      lightTransPrimary: "rgba(237, 124, 2 , 0.22)",
      activePrimary: "#dc7100",
    };
  }
  if (color === "pink" && theme === "light") {
    return {
      primary: "#F25CBD",
      primary2: "#F25CBD",
      lightPrimary: "#f07ac7",
      transPrimary: "rgba(242, 92, 189 , 0.8)",
      lightTransPrimary: "rgba(242, 92, 189 , 0.22)",
      activePrimary: "#f326ab",
    };
  }
  if (color === "pink" && theme === "dark") {
    return {
      primary: "#ca3b98",
      primary2: "#ca3b98",
      lightPrimary: "#f07ac7",
      transPrimary: "rgba(242, 92, 189 , 0.8)",
      lightTransPrimary: "rgba(242, 92, 189 , 0.22)",
      activePrimary: "#f326ab",
    };
  }
  if (color === "rose" && theme === "light") {
    return {
      primary: "#D22549",
      primary2: "#D22549",
      lightPrimary: "#ce526b",
      transPrimary: "rgba(206, 82, 107 , 0.8)",
      lightTransPrimary: "rgba(206, 82, 107 , 0.22)",
      activePrimary: "#c5193b",
    };
  }
  if (color === "rose" && theme === "dark") {
    return {
      primary: "#ae1e3c",
      primary2: "#ae1e3c",
      lightPrimary: "#ce526b",
      transPrimary: "rgba(206, 82, 107 , 0.8)",
      lightTransPrimary: "rgba(206, 82, 107 , 0.22)",
      activePrimary: "#c5193b",
    };
  }
  if (color === "green" && theme === "light") {
    return {
      primary: "#357941", 
      primary2: "#357941",
      lightPrimary: "#5A9B6A", 
      transPrimary: "rgba(53, 121, 65, 0.8)", 
      lightTransPrimary: "rgba(53, 121, 65, 0.22)", 
      activePrimary: "#2A6235", 
    };
  }
  if (color === "green" && theme === "dark") {
  return {
    primary: "#2A6235", // Original dark green
    primary2: "#008D55", // Adjusted based on the ratio from dark blue
    lightPrimary: "#5A9B6A", // The same lighter variant
    transPrimary: "rgba(42, 98, 53, 0.8)", // Transparent darker green
    lightTransPrimary: "rgba(42, 98, 53, 0.22)", // Lighter transparent variant
    activePrimary: "#357941", // Original flag green
  };
}

  if (color === "fuchsia" && theme === "light") {
    return {
      primary: "#C94CBE",
      primary2: "#C94CBE",
      lightPrimary: "#E08EDB",
      transPrimary: "rgba(137, 77, 149, 0.8)",
      lightTransPrimary: "rgba(137, 77, 149, 0.22)",
      activePrimary: "#561a62",
    };
  }
  if (color === "fuchsia" && theme === "dark") {
    return {
      primary: "#B738AD",
      primary2: "#B738AD",
      lightPrimary: "#C969BA",
      transPrimary: "rgba(180, 8, 214, 0.8)",
      lightTransPrimary: "rgba(180, 8, 214, 0.22)",
      activePrimary: "#9006ab",
    };
  }

  if (color === "all" && theme === "light") {
    return {
      blue: "#1768b3",
      teal: "#008796",
      purple: "#6c207b",
      maroon: "#AC1C37",
      phosphoric: "#becf44",
      orange: "#ED7C02",
      pink: "#F25CBD",
      rose: "#D22549",
      green: "#93C572",
      fuchsia: "#C94CBE",
    };
  }
  if (color === "allText" && theme === "light") {
    return {
      blue: "#1768b3",
      teal: "#008796",
      purple: "#6c207b",
      maroon: "#AC1C37",
      phosphoric: "#becf44",
      orange: "#ED7C02",
      pink: "#F25CBD",
      rose: "#D22549",
      green: "#93C572",
      fuchsia: "#C94CBE",
    };
  }
  if (color === "allText" && theme === "dark") {
    return {
      blue: "#006fd5",
      teal: "#008796",
      purple: "#b408d6",
      maroon: "#AC1C37",
      phosphoric: "#97a626",
      orange: "#ba6305",
      pink: "#ca3b98",
      rose: "#ae1e3c",
      green: "#62834C",
      fuchsia: "#B738AD",
    };
  }

  if (color === "all" && theme === "dark") {
    return {
      blue: "#124d84",
      teal: "#085d67",
      purple: "#682475",
      maroon: "#AC1C37",
      phosphoric: "#97a626",
      orange: "#ba6305",
      pink: "#ca3b98",
      rose: "#ae1e3c",
      green: "#62834C",
      fuchsia: "#B738AD",
    };
  } else {
    return {
      primary: "#357941", 
      primary2: "#357941",
      lightPrimary: "#5A9B6A", 
      transPrimary: "rgba(53, 121, 65, 0.8)", 
      lightTransPrimary: "rgba(53, 121, 65, 0.22)", 
      activePrimary: "#2A6235", 
    };
  }
};
export const useColors = () => {
  const { color, theme } = useModeContext();
  return colorDetector({ color, theme });
};
export const useAllColors = () => {
  const { theme } = useModeContext();
  return colorDetector({ color: "all", theme });
};
export const useAllTextColors = () => {
  const { theme } = useModeContext();
  return colorDetector({ color: "allText", theme });
};
