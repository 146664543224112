export const API_URL = "https://app-api.molhamteam.com";
export const API_URL2 = "https://app-api.molhamteam.com";
// process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337";

export const NEXT_URL = "/api";
export const NEXT_URL_PROXY = "/api/proxy";
export const AUTH_URL = `${NEXT_URL_PROXY}/auth`;

export const blogsURL = `blog-posts`;
export const faqsURL = "faqs";
// process.env.NEXT_PUBLIC_NEXT_API_URL || "http://localhost:3000/api/account";

export const STRIPE_PUBLIC_KEY = "pk_test_UQmoHKwRjJvXtgtD62koOHol00qNzOdbR2";

export const STRIPE_SECRET_KEY = "sk_test_rWaVeJAcQYStJcShQeoxWUHg005redZKzG";

export const profileAvatar = `/assets/img/profile-avatar.png`;
export const tragetableFallbackImg = "/assets/img/white-splash-small169.jpg";
export const eventsFallback = "/assets/img/events.png";

export const genericFallbackImg = "/assets/img/white-splash-smallerer.png";
export const darkThemeImg = "/assets/img/dark.png";
export const lightThemeImg = "/assets/img/light.png";

export const molhamLogo256 = "/assets/img/molham-logo-256.png";
export const SplashLogo = "/assets/img/SplashScreenLogo.png";
export const PoweredBy = "/assets/img/PoweredBy.png";

export const CANONICAL_DOMAIN =
  `https://${process.env.NEXT_PUBLIC_VERCEL_URL}` || "https://molhamteam.com";
