import { yupResolver } from "@hookform/resolvers/yup";
import { NEXT_URL_PROXY } from "config";
import useMobile from "customHooks/useMobile";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { postData } from "utils/postData";
import * as Yup from "yup";
import { AuthContext } from "../../../context/AuthContext";
import { AuthModalContext } from "../../../context/AuthModalContext";
import useRTL from "../../../customHooks/useRTL";
import { isEmpty } from "../../../utils/isEmpty";
import MolhamButton from "../MolhamButton";
import MolhamInput from "../MolhamInput";
import checkoutActions from "../SharedDonatePages/DonateModal/actions/checkout";
import { useCheckoutStore } from "../SharedDonatePages/DonateModal/CheckoutState";

type Props = {
  fromPage?: boolean;
  fromCheckoutId?: number;
};

const SignInForm = ({ fromPage, fromCheckoutId }: Props) => {
  const { t } = useTranslation("common");
  const [isConfirm, setIsConfirm] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t(`email_required`)).email(t(`email_invalid`)),
    // password: Yup.string()
    //   .min(6, t(`password_must_be_6`))
    //   .required(t(`password_required`)),
  });

  const { handleSubmit, watch, formState, control } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
  });

  const { isDirty, errors } = formState;
  const [loading, setLoading] = useState(false);
  const { login, isLoading, error } = useContext(AuthContext);
  const { setStep } = useContext(AuthModalContext);
  const setCO = useCheckoutStore().setCheckoutOrder;
  const {
    handleSubmit: handleCodeSubmit,
    control: controlCode,
    formState: { errors: codeErrors },
  } = useForm();
  const isRTL = useRTL();

  const onSubmit = async (data: { email: string }) => {
    setLoading(true);
    try {
      const x = await postData(
        `${NEXT_URL_PROXY}/donors/send-one-time-password`,
        data,
        null,
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      ); // TODO: FIX DIS THING
      // setHideModal(true);
      // toast.success(t("code_sent"));
      setIsConfirm(true);

      // router.reload();
    } catch (err) {
      if (err?.response?.data?.error?.code === "donor_email_does_not_exist") {
        toast.error(t("email_not_exists"));
      } else {
        toast.error(err.code);
      }
    }

    setLoading(false);
  };

  const onCodeSubmit = async (data) => {
    setLoading(true);

    try {
      await login({ ...data, email: watch("email") });
      if (fromCheckoutId) {
        const res = await checkoutActions.getCheckoutOrder({
          idOrReference: fromCheckoutId,
        });

        if (res.ok) {
          setCO(res.data);
        }
      }
      toast.success(t("email_verified"));
    } catch (err) {
      toast.error(err.message);
    }

    setLoading(false);
  };

  const onCodeStepComplete = async () => {
    handleCodeSubmit(onCodeSubmit)();
  };
  const isMobile = useMobile("md");
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {!isConfirm ? (
        <div className=" d-flex flex-column align-items-center w-100">
          <p className="fs-lg mb-4 text-center">{t(`login_description`)}</p>
          <form
            className={`w-100 ${error || !isEmpty(errors) ? "invalid" : ""}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <MolhamInput
              control={control}
              watch={watch}
              name="email"
              // label={t(`email`)}
              placeholder={t(`email`) + "..."}
              type="email"
            />
            {/* <MolhamInput
          control={control}
          watch={watch}
          name="password"
          // label={t(`password`)}
          placeholder={t(`password`) + "..."}
          type="password"
          /> */}

            <MolhamButton
              id="login-btn"
              label={t(`login`)}
              isLoading={isLoading || loading}
              endIcon="fe-arrow-right"
              disabled={!isDirty}
              className="mb-4 w-100"
            />

            {/* <div className="d-flex justify-content-end align-items-center mb-4">
          {fromPage ? (
            <Link
            href="/forgot-password"
            prefetch={false}
            className="nav-link-style fs-md cursor"
            >
            {t(`forgot_password`)}
            </Link>
            ) : (
              <a
              className="nav-link-style fs-md cursor"
              onClick={() => setStep(3)}
              >
              {t(`forgot_password`)}
              </a>
              )}
              </div> */}
            {fromPage ? (
              <p className="fs-md mb-0 text-center">
                {t(`dont_have_account`)}{" "}
                <Link
                  prefetch={false}
                  href="/signup"
                  className="fw-medium cursor"
                >
                  {t(`sign_up`)}
                </Link>
              </p>
            ) : (
              <p className="fs-md mb-0 text-center">
                {t(`dont_have_account`)}{" "}
                <a onClick={() => setStep(2)} className="fw-medium cursor">
                  {t(`sign_up`)}
                </a>
              </p>
            )}
          </form>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center w-100">
          <form
            className={`w-100 needs-validation ${
              !isEmpty(codeErrors) ? "invalid" : ""
            }`}
            onSubmit={handleCodeSubmit(onCodeSubmit)}
          >
            <div className="d-flex flex-column align-items-center w-100">
              <div
                className="text-center p-4 bg-secondary mb-4
"
                style={{ width: "fit-content", borderRadius: "50%" }}
              >
                <i
                  className="ai-mail text-primary "
                  style={{ fontSize: 80, padding: 12 }}
                ></i>
              </div>
              <p className="h3 mb-4  text-center">{t("email_verification")}</p>
              <p className={`fs-md mb-4 text-center`}>
                {t("email_verification_description")}
              </p>
              <p
                className={`h4 ${
                  isMobile ? "fs-md" : "fs-lg"
                } mb-4 text-center`}
              >
                {watch("email")}
              </p>
              <Controller
                name="one_time_password"
                control={controlCode}
                rules={{
                  required: t(`code_required`),
                }}
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    inputType="number"
                    onChange={(otp) => {
                      field.onChange(otp);
                      if (otp.length == 6) onCodeStepComplete();
                    }}
                    containerStyle={{
                      direction: "ltr",
                    }}
                    value={field.value}
                    renderInput={(props) => {
                      return (
                        <input
                          {...props}
                          disabled={isLoading || loading}
                          style={{
                            width: "40px",
                            height: "40px",
                            margin: "0rem 5px 1rem 5px",
                            padding: isRTL ? "6px 0px 6px 0px" : 0,
                            color: theme == "light" ? "#000" : "#fff",
                          }}
                          className="form-control-num text-center form-control-active form-control-bg"
                        />
                      );
                    }}
                  />
                )}
              />

              {codeErrors.code?.message && (
                <div className="invalid-feedback text-center ">
                  {/* @ts-ignore */}
                  {codeErrors.code?.message}
                </div>
              )}
            </div>
          </form>
          <MolhamButton
            id="verification-resend-code-btn"
            label={t("resend_verification_code")}
            className="w-100 text-primary"
            variant={null}
            disabled={isLoading || loading}
            isLoading={isLoading || loading}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      )}
    </>
  );
};

export default SignInForm;
