import { MolhamDonorData } from "apiTypes";
import axios from "axios";
import { getClarity } from "config/clarity";
import { getMixpanel } from "config/mixpanel";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import Cookies from "js-cookie";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";
import { manageSessionReference } from "utils/manageSessionReference";
import { sendEvent } from "utils/sendEvent";
import { NEXT_URL } from "../config";
import { AuthModalContext } from "./AuthModalContext";
export type AuthContextType = {
  donor: MolhamDonorData | null;
  setDonor: Dispatch<SetStateAction<MolhamDonorData>>;
  error: any;
  signup: (email: string, password: string, name: string) => Promise<void>;
  login: (data: any) => Promise<void>;
  signOut: any;
  isLoading: boolean;
  isDonorLoggedIn: boolean;
  setRedirect: Dispatch<SetStateAction<boolean>>;
  mutateDonor: KeyedMutator<MolhamDonorData>;
  finishedLoadingOnce: boolean;
};

const AuthContext = createContext<AuthContextType | null>(null);

const NotMemoizedAuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [donor, setDonor] = useState<MolhamDonorData | null>(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDonorLoggedIn, setIsDonorLoggedIn] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const { handleCloseAuthModal } = useContext(AuthModalContext);
  const { t } = useTranslation();
  const [checkOnce, setCheckOnce] = useState(false);
  const router = useRouter();
  const check = () => {
    if (!checkOnce && donor) {
      setCheckOnce(true);
      return donor ? `${NEXT_URL}/account/user` : null;
    }
    return `${NEXT_URL}/account/user`;
  };
  const { mutate } = useSWRConfig();
  useEffect(() => {
    if (
      donor &&
      router &&
      router?.query &&
      router.query.return_url &&
      !Array.isArray(router.query.return_url)
    ) {
      const uri = decodeURIComponent(router.query.return_url);
      router.push(uri);
    }
  }, [router.query.return_url, donor]);
  const [finishedLoadingOnce, setFinishedLoadingOnce] = useState(false);
  // const firebaseAnalytics = useFirebaseAnalytics();
  // using useSwr to fetch the donor if it's exist and call the api every 1 minute
  const swrFromCtx = useSWR<MolhamDonorData>(check, {
    refreshInterval: 1000 * 90,
    revalidateOnFocus: true,

    onSuccess(data) {
      setFinishedLoadingOnce(true);
      setIsDonorLoggedIn(false);
      setDonor({ ...data });
      const firebaseAnalytics = getAnalytics();
      if (firebaseAnalytics) {
        setUserId(firebaseAnalytics, data.id.toString());
      }
      const clarity = getClarity();
      if (clarity) {
        clarity("identify", data.id.toString());
      }
      const mixpanel = getMixpanel();
      if (mixpanel) {
        mixpanel.identify(data.id.toString());
        mixpanel.people.set({
          $email: data.email,
          $name: data.name,
          $avatar: data.avatar?.thumbnails?.["1:1"],
          $country_code: data.country.code,
          $phone: data.phone,
          currency: data.currency?.code,
          locale: data.locale,
          active_subscriptions_count: data.active_subscriptions_count,
        });
      }
    },
    onError(err) {
      setFinishedLoadingOnce(true);

      if (err.status === 403) {
        setIsDonorLoggedIn(false);
        setDonor(null);
        const firebaseAnalytics = getAnalytics();

        if (firebaseAnalytics) {
          setUserId(firebaseAnalytics, null);
        }
        mutate(
          (key) => {
            return (
              typeof key === "string" &&
              (key.includes("/auth") || key.includes("/account/user"))
            );
          },
          undefined,
          false
        );
        if (router.pathname.includes("/profile")) {
          router.push("/");
        }
      }
    },
  });

  // signup user
  /**
   * It signs up a user.
   * @param email - The email of the user.
   * @param password - The password of the user.
   * @param name - The name of the donor.
   */
  const signup = async (email, password, name) => {
    setIsLoading(true);

    const locale = Cookies.get("locale");
    const currency = Cookies.get("currency");
    try {
      const res = await axios.post(
        `https://app-api.molhamteam.com/donors`,
        {
          email,
          name,
          password,
        },
        {
          headers: {
            lang: locale,
            currency,
          },
        }
      );

      if (res.status === 200) {
        const firebaseAnalytics = getAnalytics();

        if (firebaseAnalytics) {
          logEvent(firebaseAnalytics, "sign_up", {
            method: "email",
          });
        }
        const mixpanel = getMixpanel();
        if (mixpanel) {
          mixpanel.identify(res.data.id.toString());
          mixpanel.track("Signup");
        }
        sendEvent({
          pixel: "SignUp",
        });
        await login({ email, password }, true);
      }
      setError(null);

      handleCloseAuthModal();
      // toast.success(`You are in , Welcome ${res.data.name}`);

      if (router.query.return_url) {
        router.push(`${router.query.return_url}`);
      } else {
        if (
          redirect ||
          window.location.pathname === "/login" ||
          window.location.pathname === "signup"
        )
          router.push(`/`);
      }

      setIsLoading(false);
    } catch (error) {
      if (error.response?.data?.error?.code === "donor_email_already_exists") {
        toast.error("Email already exists");
        setIsLoading(false);
        return;
      }
      if (error.response.data.errors) {
        const keys = Object.keys(error.response.data.errors);
        setError({
          message: error.response.data.message,
          errors: error.response.data.errors,
          keys,
        });
      }
      setIsLoading(false);
    }
  };

  // Login user
  /**
   * It makes a post request to the server to login a user.
   * @param email - The email of the user.
   * @param password - The password of the user.
   * @param remember_me - Boolean, if the user wants to stay logged in.
   */

  const login = async (data, fromSignup: boolean = false) => {
    setIsLoading(true);

    let urlencoded = new URLSearchParams();
    if (data.facebook_access_token) {
      urlencoded.append("facebook_access_token", data.facebook_access_token);
      urlencoded.append("facebook_user_id", data.facebook_user_id);
    } else {
      urlencoded = data;
    }
    try {
      await axios.post(`${NEXT_URL}/account/login`, urlencoded);
      const firebaseAnalytics = getAnalytics();

      if (firebaseAnalytics && !fromSignup) {
        logEvent(firebaseAnalytics, "login");
      }
      const donorRes = await axios.get(`${NEXT_URL}/account/user`);

      setDonor({ ...donorRes.data });
      handleCloseAuthModal();
      setCheckOnce(false);
      setError(null);
      const windowUrl = new URL(window.location.href);
      const return_url = windowUrl.searchParams.get("return_url");
      if (return_url) {
        router.push(`${return_url}`);
      } else {
        if (
          redirect ||
          window.location.pathname === "/login" ||
          window.location.pathname === "signup"
        ) {
          router.push(`/`);
        }
      }

      setIsLoading(false);
    } catch (error) {
      setError({
        error: error.response?.data?.error,
      });
      setIsLoading(false);
      if (error.response?.data?.error?.code !== "invalid_donor_credentials") {
        toast.error(error.message);
      } else {
        throw error.response?.data?.error?.code;
      }
    }
  };

  const signOut = async () => {
    setIsLoading(true);
    try {
      await axios.post(`${NEXT_URL}/account/signout`);
      const mixpanel = getMixpanel();
      manageSessionReference().removeSessionReference();
      if (mixpanel) {
        mixpanel.reset();
      }
      if (window.location.pathname.includes("/profile")) {
        router.push(`/`);
      }
      toast.success(t("signed_out"));
      setDonor(null);
      setIsLoading(false);
      setCheckOnce(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const value: AuthContextType = useMemo(() => {
    return {
      donor,
      setDonor,
      error,
      signup,
      login,
      signOut,
      isLoading,
      isDonorLoggedIn,
      setRedirect,
      mutateDonor: swrFromCtx.mutate,
      finishedLoadingOnce,
    };
  }, [donor, error, isLoading, isDonorLoggedIn, finishedLoadingOnce]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthProvider = memo(NotMemoizedAuthProvider);
export { AuthContext, AuthProvider };

export function useAuthContext(): AuthContextType {
  const authData = useContext(AuthContext);
  if (authData == null) {
    throw new Error(
      "AuthContext is not available. Make sure you are rendering the component within the ChatSupportProvider."
    );
  }
  return authData;
}
