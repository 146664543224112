import { useContext, useState } from "react";
import styled from "styled-components";
import ActiveLink from "./ReuseableCompos/ActiveLink";

import CartOffCanvas from "./CartOffCanvas";

import { profileAvatar } from "config";
import { useFooterContext } from "context/FooterContext";
import useStickyScroll from "customHooks/useStickyScroll";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";
import { AuthContext } from "../context/AuthContext";
import { AuthModalContext } from "../context/AuthModalContext";
import { useCartContext } from "../context/CartContext";
import useModal from "../customHooks/useModal";
import useUpdateEffect from "../customHooks/useUpdateEffect";
import NavbarMenuOffCanvas from "./NavBar/NavbarMenuOffCanvas";

const FooterNav = () => {
  const [activeItem, setActiveItem] = useState("");
  const activeUrl = useRouter().pathname.split("/")[1];

  const {
    showModal: showNavbarMenuOffCanvas,
    handleCloseModal: handleCloseNavbarMenuOffCanvas,
    handleShowModal: handleShowNavbarMenuOffCanvas,
  } = useModal();
  const { handleShowCartOffCanvas, showCartOffCanvas, carts } =
    useCartContext();
  const { noBlur } = useFooterContext();

  const { donor } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const onItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const { handleShowAuthModal, onTabClick, showAuthModal } =
    useContext(AuthModalContext);
  const onSignupBtnClick = () => {
    handleShowAuthModal();
    onTabClick("with_social");
  };
  useUpdateEffect(() => {
    if (showCartOffCanvas) {
      setTimeout(() => setActiveItem(""), 100);
    }
    if (!showAuthModal) {
      setTimeout(() => setActiveItem(""), 100);
    }
  }, [showCartOffCanvas, showAuthModal]);
  const scroll = useStickyScroll(1);
  return (
    <>
      <StyledFooterNav
        id="footer-navigation"
        className={`${
          scroll && !noBlur ? "bg-blur" : "bg-white"
        } navbar-box-shadow-top dark-border-top d-flex d-lg-none`}
        style={{ transition: "all 0.5s ease-in-out" }}
      >
        <div
          style={{ maxWidth: 500 }}
          className="w-100 mx-auto d-flex justify-content-evenly"
        >
          <ActiveLink href="/">
            <a
              className={`mx-2 ${activeUrl === "" && "active"} p-0`}
              aria-label={t(`home`)}
            >
              <i className={`fe-home fs-xxl`}></i>{" "}
              <span
                className={
                  "text-nowrap" +
                  ` ${activeUrl === "" ? "fw-bold" : "fw-light"}`
                }
              >
                {t("main")}
              </span>
            </a>
          </ActiveLink>

          <div
            className={`a mx-2 position-relative cursor ${
              activeItem === "cart" ? "active" : ""
            }`}
            onClick={() => {
              handleShowCartOffCanvas();
              onItemClick("cart");
            }}
          >
            {carts?.length > 0 ? (
              <div
                style={{
                  width: 24,
                  position: "absolute",
                  fontFamily: "Arial",
                  top: 4,
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <div
                  className="position-absolute top-0 start-100 translate-middle  bg-primary "
                  style={{
                    padding: "0.1rem 0.4rem",
                    borderRadius: 8,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "var(--white)",
                    color: "white",
                    fontSize: "0.65rem",
                    lineHeight: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {carts?.length >= 100 ? "+99" : carts?.length}
                </div>
              </div>
            ) : null}
            <i className={`fe-shopping-cart fs-xxl`}></i>
            <span
              className={
                "text-nowrap" +
                ` ${activeItem === "cart" ? "fw-bold" : "fw-light"}`
              }
            >
              {t("cart")}
            </span>
          </div>
          <ActiveLink href={"/donate"}>
            <a
              className={`mx-2 navbar-tool-icon-box ${
                activeUrl === "donate" && "active"
              }  p-0`}
            >
              <i className={`fe-heart fs-xxl`}></i>
              <span
                className={
                  "text-nowrap" +
                  ` ${activeUrl === "donate" ? "fw-bold" : "fw-light"}`
                }
              >
                {t("donate")}
              </span>
            </a>
          </ActiveLink>
          {donor ? (
            <ActiveLink href={"/profile"} fuzzy>
              <a
                className={`mx-2 navbar-tool-icon-box  ${
                  activeUrl === "profile" && "active"
                }  p-0`}
              >
                {/* <i className={`fe-user fs-xxl`}></i> */}
                <Image
        unoptimized
        src={
                    donor?.avatar
                      ? donor?.avatar.thumbnails["1:1"]
                      : profileAvatar
                  }
                  alt={donor?.name}
                  width={24}
                  height={24}
                  style={
                    activeUrl === "profile"
                      ? {
                          borderRadius: "50%",
                          border: "1px solid var(--primary2)",
                          marginBottom: "1px",
                        }
                      : { borderRadius: "50%", marginBottom: "1px" }
                  }
                />
                <span
                  className={
                    "text-nowrap" +
                    ` ${activeUrl === "profile" ? "fw-bold" : "fw-light"}`
                  }
                >
                  {t("profile")}
                </span>
              </a>
            </ActiveLink>
          ) : (
            <div
              className={`a mx-2 cursor ${
                activeItem === "sign_in" ? "active" : ""
              }`}
              onClick={() => {
                onItemClick("sign_in");
                onSignupBtnClick();
              }}
            >
              <i className="fe-user fs-xxl"></i>
              <span
                className={
                  "text-nowrap" +
                  ` ${activeItem === "sign_in" ? "fw-bold" : "fw-light"}`
                }
              >
                {t("sign_in")}
              </span>
            </div>
          )}
          <div
            className={`a mx-2 cursor ${activeItem === "menu" ? "active" : ""}`}
            onClick={() => {
              handleShowNavbarMenuOffCanvas();
              onItemClick("menu");
            }}
          >
            <i className={`fe-menu fs-xxl`}></i>
            <span
              className={
                "text-nowrap" +
                ` ${activeItem === "menu" ? "fw-bold" : "fw-light"}`
              }
            >
              {t("menu")}
            </span>
          </div>
        </div>
      </StyledFooterNav>{" "}
      <NavbarMenuOffCanvas
        showNavbarMenuOffCanvas={showNavbarMenuOffCanvas}
        handleCloseNavbarMenuOffCanvas={() => {
          handleCloseNavbarMenuOffCanvas();
          onItemClick("");
        }}
      />
      <CartOffCanvas />
    </>
  );
};

export default FooterNav;

const StyledFooterNav = styled.nav`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: 0.7rem 0;
  padding-bottom: calc(0.7rem + min(env(safe-area-inset-bottom), 1.25rem));

  a,
  .a {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    color: var(--gray);
    text-decoration: none;
    transition: all 0.5s ease;
    flex: 1;
    span {
      text-align: center;
      font-size: 0.6rem !important;
    }
  }

  a.active,
  .a.active {
    color: var(--primary2);
  }
  a.active::after,
  .a.active::after {
    background-color: var(--primary2);
    transition: all 0.5s ease;
  }
`;
