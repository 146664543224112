import { PoweredBy, SplashLogo } from "config";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";

const InitalLoader = () => {
  const [hideElement, setHideElement] = useState(false);
  const [setClass, setSetClass] = useState(false);
  // const isMobile = useMobile("md");

  const ref = useRef(null);
  useEffect(() => {
    if (ref) {
      setTimeout(() => {
        setSetClass(true);
        setTimeout(() => {
          setHideElement(true);
        }, 800);
      }, 400);
    }
  }, []);
  return (
    !hideElement && (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "var(--white)",
          zIndex: 2001,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBlock: 64,
        }}
        className={setClass ? styles["fade-out"] : ""}
      >
        <div></div>
        <Image
          unoptimized
          alt="splash-logo"
          width={300}
          height={300}
          src={SplashLogo}
        />
        <div>
          <Image
            unoptimized
            alt="Powered By"
            src={PoweredBy}
            width={150}
            height={150}
          />
        </div>
      </div>
    )
  );
};

export default InitalLoader;
