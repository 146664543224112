import { yupResolver } from "@hookform/resolvers/yup";
import MolhamButton from "components/ReuseableCompos/MolhamButton";
import SelectModal from "components/ReuseableCompos/SharedDonatePages/DonateModal/components/Select/SelectModal";
import { usePreferenceContext } from "context/PreferencesContext";
import useFormatNumbers from "customHooks/useFormatNumbers";
import useUtilities from "customHooks/useUtilities";
import chunk from "lodash.chunk";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useCheckoutStore } from "../../CheckoutState";
import checkoutActions from "../../actions/checkout";
import LiteButton from "../../components/LiteButton";
import ModalBodyElementContainer from "../../components/ModalBodyElementContainer";
import ConfirmationStep from "../Confirmation/ConfirmationStep";
import NumericalInput from "../DonationAmount/components/NumericalInput";
import ProviderFeeStep from "../ProviderFee/ProviderFeeStep";
import SavedShippingAddressStep from "../ShippingAddress/SavedShippingAddressStep";
import TeamSupportStep from "../TeamSupport/TeamSupportStep";

const GeneralDonationStepTitle: React.FC = () => {
  const { t } = useTranslation("common");

  return t("general_quick_donation");
};
type TFormData = {
  amount: string;
  frequency: string;
};
const GeneralDonationStepBody: React.FC = () => {
  const { t } = useTranslation("common");
  const {
    loading,
    setLoading,
    checkoutOrder,
    setCheckoutOrder,
    setGeneralDonationFrequency,
    generalDonationFrequency,
    quickDonationPaymentOption,
    setQuickDonationPaymentOption,
    setDonationAmountValue,
    setTeamsupport,
    setProviderFee,
    addStepToHistory,
    donationAmountValue,
    generalDonationCustomAmount,
    setGeneralDonationCustomAmount,
  } = useCheckoutStore((state) => ({
    addStepToHistory: state.addStepToHistory,
    setLoading: state.setLoading,
    checkoutOrder: state.checkoutOrder,
    setCheckoutOrder: state.setCheckoutOrder,
    setTeamsupport: state.setTeamSupport,
    setProviderFee: state.setProviderFee,
    quickDonationPaymentOption: state.quickDonationPaymentOption,
    setQuickDonationPaymentOption: state.setQuickDonationPaymentOption,
    setGeneralDonationFrequency: state.setGeneralDonationFrequency,
    generalDonationFrequency: state.generalDonationFrequency,
    generalDonationCustomAmount: state.generalDonationCustomAmount,
    setGeneralDonationCustomAmount: state.setGeneralDonationCustomAmount,
    loading: state.loading,
    setDonationAmountValue: state.setDonationAmountValue,
    donationAmountValue: state.donationAmountValue,
  }));
  const { data: utilities } = useUtilities();

  const {
    curr,
    lang: { shortcut: locale },
    currencies,
  } = usePreferenceContext();
  const currencyCode = useCheckoutStore()?.checkoutOrder?.currency?.code;

  const currencyOptions = {
    accept_decimals:
      utilities?.currencies?.find(
        (v) => v.code === (currencyCode ?? curr?.code)
      )?.accept_decimals ?? true,
    min_payable_amount:
      utilities?.currencies?.find(
        (v) => v.code === (currencyCode ?? curr?.code)
      )?.min_payable_amount ?? 1,
  };
  const schema = useMemo(
    () =>
      yup.object().shape({
        amount: yup
          .number()
          .min(
            currencyOptions.min_payable_amount,
            t("minimum_is_x") + " " + currencyOptions.min_payable_amount
          )
          .max(10000, t("maximum_is_x") + " 10000")
          .required(t("amount_required"))
          .typeError(t("must_be_number")),
        frequency: yup.string().required(t("required")),
      }),
    [currencyOptions.min_payable_amount, t]
  );
  const { formatNumbers } = useFormatNumbers();
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TFormData>({
    defaultValues: {
      amount:
        utilities?.currencies
          .find((v) => v.code === (currencyCode ?? curr?.code))
          ?.familiar_amounts?.[0]?.toString() || "0",
      frequency: "one_time",
    },
    resolver: yupResolver(schema),
  });

  const handleNextSlide = async (data: TFormData) => {
    setLoading(true);
    setGeneralDonationFrequency(data.frequency);
    try {
      if (data.frequency && quickDonationPaymentOption?.id[data.frequency]) {
        const resPromise = !checkoutOrder
          ? checkoutActions.createCheckoutOrder({
              currency_code: currencyCode ?? curr?.code,
              amount: data.amount,
              payment_option_id: quickDonationPaymentOption.id[data.frequency],
            })
          : checkoutActions.updateCheckoutOrder({
              currency_code: currencyCode ?? curr?.code,
              amount: data.amount,
              payment_option_id: quickDonationPaymentOption.id[data.frequency],
              id: checkoutOrder?.id,
            });

        const res = await resPromise;
        if (res.ok === true) {
          setCheckoutOrder(res.data);
         
          setDonationAmountValue(data.amount);
          if (res.data.team_support_suggested) {
            addStepToHistory(TeamSupportStep.name);
          } else if (res.data.payment_provider_fees_checkout_suggestion) {
            addStepToHistory(ProviderFeeStep.name);
          } else if (res.data.shipping_address_required) {
            addStepToHistory(SavedShippingAddressStep.name);
          } else {
            addStepToHistory(ConfirmationStep.name);
          }
        } else {
          toast.error(res.error.message);
        }
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (donationAmountValue) {
      setValue("amount", donationAmountValue);
    }
  }, [donationAmountValue, utilities?.currencies]);
  useEffect(() => {
    if (generalDonationFrequency) {
      setValue("frequency", generalDonationFrequency);
    }
  }, [generalDonationFrequency]);
  useEffect(() => {
    if (utilities && !quickDonationPaymentOption) {
     
      // log search params
      const id = window.location.search.includes("id=")
        ? Number(window.location.search.split("id=")[1])
        : null;

      if (id) {
        setQuickDonationPaymentOption(
          utilities.general_quick_donation_payment_options.find(
            (v) => v.id.one_time === id
          )
        );
        if (id === 4) {
          setTeamsupport("10");
          setProviderFee(true);
        }
      } else {
        setQuickDonationPaymentOption(
          utilities.general_quick_donation_payment_options[0]
        );
      }
    }
  }, [utilities]);

  const familierAmountsMap = chunk(
    [
      //   { value: "0", label: t(`none`) },
      ...(utilities?.currencies
        .find((v) => v.code === (currencyCode ?? curr?.code))
        ?.familiar_amounts?.map((v) => ({
          value: v.toString(),
          label: formatNumbers(v, currencyCode ?? curr?.code),
        })) || []),
      { value: "custom", label: t(`custom_amount`) },
    ],
    3
  );
  const watchAmount = watch("amount");
  const frequencysMap = quickDonationPaymentOption
    ? chunk(
        [
          { value: "one_time", label: t(`one_time_donation_type`) },
          { value: "week", label: t(`week_donation_type`) },
          { value: "month", label: t(`month_donation_type`) },
        ].filter((item) =>
          Object.keys(quickDonationPaymentOption?.id).includes(item.value)
        ),
        3
      )
    : [];
  return (
    <ModalBodyElementContainer className="d-flex flex-column align-items-center justify-content-center">
      {utilities && quickDonationPaymentOption ? (
        <div className="mb-3 w-100">
          <label>{t("purpose")}</label>
          <SelectModal
            title={t("purpose")}
            swipeToClose={false}
            onOptionChange={(v) => {
              setQuickDonationPaymentOption(v.value);
              setValue("frequency", Object.keys(v.value.id)[0]);
            }}
            options={utilities.general_quick_donation_payment_options.map(
              (option) => ({
                label: option.name[locale],
                description: option.description[locale],
                value: option,
              })
            )}
            selectedOption={{
              label: quickDonationPaymentOption.name[locale],
              description: quickDonationPaymentOption.description[locale],
              value: quickDonationPaymentOption,
            }}
          />
        </div>
      ) : null}
      <Controller
        name="frequency"
        defaultValue={generalDonationFrequency}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            {frequencysMap.map((amountRow, amountIdx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                  key={amountIdx}
                >
                  {amountRow.map((v, i) => {
                    const toRender = [];
                    if (i !== 0) {
                      toRender.push(
                        <div key={`gap-${i}`} style={{ width: 10 }} />
                      );
                    }

                    toRender.push(
                      <LiteButton
                        key={v.value}
                        text={v.label}
                        selected={value == v.value}
                        onClick={() => {
                          onChange(v.value);
                        }}
                        // style={{ width: 10,}}
                      />
                    );

                    return toRender;
                  })}
                </div>
              );
            })}
          </>
        )}
      />
      <Controller
        name="amount"
        defaultValue={donationAmountValue}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <>
            {familierAmountsMap.map((amountRow, amountIdx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                  key={amountIdx}
                >
                  {amountRow.map((v, i) => {
                    const toRender = [];
                    if (i !== 0) {
                      toRender.push(
                        <div key={`gap-${i}`} style={{ width: 10 }} />
                      );
                    }
                    if (v.value === "custom") {
                      toRender.push(
                        <LiteButton
                          key={v.value}
                          text={v.label}
                          selected={generalDonationCustomAmount}
                          onClick={() => {
                            setGeneralDonationCustomAmount(true);
                            onChange("");
                          }}
                          // style={{ width: 10,}}
                        />
                      );
                    } else {
                      toRender.push(
                        <LiteButton
                          key={v.value}
                          text={v.label}
                          selected={
                            value == v.value && !generalDonationCustomAmount
                          }
                          onClick={() => {
                            setGeneralDonationCustomAmount(false);
                            onChange(v.value);
                          }}
                          // style={{ width: 10,}}
                        />
                      );
                    }
                    return toRender;
                  })}
                </div>
              );
            })}

            {generalDonationCustomAmount ? (
              <div className="mb-4 w-100">
                <NumericalInput
                  value={value}
                  onBlur={onBlur}
                  autoFocus
                  onChange={(val) => {
                    onChange(val);
                  }}
                  error={t(errors.amount?.message)}
                  currencyCode={currencyCode || curr.code}
                  currencySymbol={
                    currencies.find((c) => c.code === currencyCode)?.symbol ||
                    curr.symbol
                  }
                  placeholder={`${t("donation_amount_placeholder_partial")}${t(
                    `${currencyCode ?? curr?.code}_currency_name`
                  )}`}
                />
              </div>
            ) : null}
          </>
        )}
      />

      <MolhamButton
        id="donate-general-btn"
        label={
          <>
            {t("proceed_to_checkout") + " ("}
            <b>
              {formatNumbers(watchAmount, currencyCode ?? curr?.code, true)}
            </b>
            {")"}
          </>
        }
        className="w-100"
        disabled={loading}
        isLoading={loading}
        onClick={handleSubmit(handleNextSlide)}
        endIcon="fe-arrow-right"
      />
    </ModalBodyElementContainer>
  );
};
const GeneralDonationStep = {
  name: "GeneralDonationStep",
  title: GeneralDonationStepTitle,
  body: GeneralDonationStepBody,
  // footer: GeneralDonationStepFooter,
};
export default GeneralDonationStep;
